.survey {
    padding: 20px 0;
    display: flex;
    justify-content: center;

    sub {
        font-size: .7em;
    }

    .survey-container {
        max-width: 64%;
    }
}

@media (max-width: 991.98px) {
    .survey {
        .survey-container {
            max-width: initial;
            padding: 0 15px;
        }
    }
}

.survey-header {
    border-radius: 6px;
    border-bottom: 5px solid #5f7e88;
    background-color: white;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);

    padding: 20px;
    margin: 0;

    h3 {
        margin: 0;
    }

    .page-counter {
        margin-top: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
}

.survey-header-question {
    .question-intro {
        font-size: .85em;
    }
    .description {
        background: white;
        font-size: .9em;
        margin-top: 15px;
    
        p {
            margin: 0;
        }
    }
}

.survey-question {
    padding: 1px 22px;
    background: #5f7e88;
    color: #fff;
    margin-top: 30px;
    margin: 0 -20px;
}

.survey-light-container {
    padding: 10px;
    background: rgba(157, 173, 190, .12941176470588237);
    border-radius: 4px;
    margin: 12px 0 17px;

    li {
        padding-left: 10px;
        font-weight: 700;
        line-height: 1.4;

        * {
            font-weight: normal;
            color: black;
        }
    }
}

.survey-item-number {
    margin: 18px 0 8px;
    font-size: 18px;
    padding: 10px 0;
    font-weight: 700;
    color: #5f7e88;
    min-width: 25px;
}

.survey-btns {
    button {
        width: 100%;

        &.active {
            background-color: #5f7e88 !important;
            color: #fff;
        }
    }

    .or {
        text-align: center;
        font-size: 14px;
    opacity: .5;
    }
}

.continue-container {
    display: flex;
    justify-content: right;
    button {
        margin-top: 15px;
        width: 170px;
    }
}