.noSelect {
	cursor: pointer;
	touch-action: none;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.compassWrapper{
	position: relative;
	display: inline-flex;
	// border: 1px solid #0002;
	// border-radius: 4px;
}

.restartBtn{
	position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px 14px;
  font-size: 14px;
  margin: 5px 0;
  opacity: 0;
	animation: fadeIn .3s forwards;
}

.compassLayout{
	display: flex;
	justify-content: center;
  	margin: 30px 0 0;
	position: relative;
}

.rightText{
	position: absolute;
  top: 0;
	left: 0;
  left: 0;
	display: flex;
	justify-content: center;
	width: 100%;
  padding: 10px 0;
  opacity: 0;
	animation: fadeIn .3s forwards;
}

.firstLetter{
	padding-right: 8px;
	font-weight: bold;
}

@keyframes fadeIn {
	from{
		opacity: 0;
	}
	to{
		opacity: 1;
	}
}

@media (max-width: 767.98px){
	.compassWrapper{
		margin: 40px 0 0 -120px;
	}
}
