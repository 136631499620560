.nav-bar {
    position: relative;
    background-color: #f9f9f9;

    padding-right: 12px;
    padding-left: 12px;
    min-height: 69px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 69px;

    .nav-logo {
        height: 50px;

        &.full {
            display: block;
        }

        &.small {
            display: none;
        }
    }

    .right {
        text-align: right;
    }
        
    .nav-bar-buttons button {
        margin-left: 20px;
    }

    .nav-bar-register a {
        font-weight: 700;
        padding-left: 5px;

        &:hover {
            text-decoration: underline;
        }
    }

    .big-screen {
        display: block;
    }
}

@media (max-width: 750px) {
    .nav-bar-container {
        position: fixed;
        z-index: 10;
    }

    .nav-bar {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        
        .nav-logo {
            &.full {
                display: none;
            }
    
            &.small {
                display: block;
            }
        }

        .big-screen {
            display: none;
        }
    }
}