.hamburger {
    display: none;
}

.hamburger-icon {
    width: 40px;
    height: 40px;

    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
}

.hamburger-icon-bar {
    position: relative;

    width: 40px;
    height: 5px;

    background-color: #448296;
    border-radius: 3px;

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 5px;

        top: -12px;
        left: 0px;
        background-color: #448296;
        border-radius: 3px;
    }

    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 5px;

        top: 12px;
        left: 0px;
        background-color: #448296;
        border-radius: 3px;
    }
}

.hamburger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    width: 100%;
    background: red;

    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;

    transform: translateY(100%);
    
    background-color: #f9f9f9;

    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    display: none;

    &.visible {
        display: flex;
    }
}

@media (max-width: 750px) {
  .hamburger {
    display: block;
  }
}