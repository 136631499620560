.home {
    .home-img-container {
        width: 51%;
        position: absolute;
        top: 70px;
        right: 20px;
        height: calc(100vh - 100px);
        overflow: hidden;

        img {
            width: 100%;
            margin-bottom: 100px;
            max-width: 700px;
        }
    }

    .home-presentation-container {
        padding-right: 12px;
        padding-left: 12px;
        margin-bottom: 50px;
        z-index: 1;
        position: relative;

        .home-presentation {
            margin-right: -12px;
            margin-left: -12px;
            min-height: calc(100vh - 150px);
            height: 200px;
            display: flex;
            justify-content: flex-start;
            justify-content: center;
            flex-wrap: wrap;
            flex-direction: column;
            height: 100%;
            padding: 0 40px 0 0;
            
            h1 {
                font-size: 40px;
            }

            .sub-text {
                white-space: pre-wrap;
                font-size: 22px;
                font-weight: lighter;
                line-height: 1.8;

                margin-bottom: 25px;
            }
        }

        .home-buttons button {
            margin-right: 15px;
            min-width: 230px;
        }
    }

    .home-full-description {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 11%;

        .full-logo-container {
            max-width: 33%;
    
            img {
                width: 100%;
            }
        }
        
        .info-container {
            border: 1px solid #5f7e88;
            padding: 0 12px;
            margin: 30px 0 20px;
            border-radius: 3px;
        }

        h3 {
            margin-top: 50px;
        }
    }
}

@media (max-width: 991.98px) {
    .home {
        .home-img-container {
          display: none;
        }

        .home-full-description {
            margin: 0;
        }

        .home-presentation-container {
            .home-presentation {
                padding: 0;
            }
        }
    }
}

@media (max-width: 750px) {
    .home {
        .home-buttons button {
            width: 100%;
        }
    }
}