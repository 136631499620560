html, body {
  padding: 0;
  margin: 0;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: #f9f9f9;
}

body.no-scroll {
  overflow: hidden;
}



* {
  box-sizing: border-box;
}

.main-container {
  max-width: 1140px;

  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

p {
  line-height: 1.4;
}

button {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  display: inline-block;
  text-align: center;
  text-transform: none;
  letter-spacing: 0;
  width: unset;
  border-radius: 6px;
  border-image: initial;
  margin: 8px 0;
  color: #5f7e88;
  border: 1px solid #5f7e88;
  font-size: 15px;
  box-shadow: none;
  padding: 10px 20px;
  transition: .2s;
  min-width: 130px;
  background: #f9f9f9;

  &:active {
    box-shadow: 0 0 0 3px #fff,0 0 0 4px #717171,0 0 0 5px hsla(0,0%,100%,.5)!important;
    outline: none !important;
    transition: box-shadow .2s ease !important;
  }

  &.outlined {
    color: #5f7e88;
    border: 1px solid #5f7e88;
    background-color: hsla(0, 0%, 100%, 0);
  }

  &.btn-opaque {
    border: 1px solid #448296;
    background-color: #5f7e88;
    color: white;

    &:hover {
      background-color: #448296;
    }
  }

  &.btn-light:hover {
    background-color: rgba(68, 130, 150, .1);
  }

  &.no-border {
    border: none;
  }
}


input {
  outline: none;
  font-size: 16px;
  padding: 11px;
  border-radius: 5px;
  border: 1px solid #dcdce0;
  transition: all .2s, padding 0s;
  width: 100%;
  line-height: 22px;
  margin-bottom: 16px;
  -webkit-appearance: none;
  appearance: none;

  &:active, &:focus {
    border-color: #5f7e88;
  }
}

a {
  color: #5f7e88;
  text-decoration: none;
}


textarea {
  outline: none;
    font-size: 16px;
    padding: 11px;
    border-radius: 5px;
    border: 1px solid #dcdce0;
    transition: all .2s, padding 0s;
    width: 100%;
    line-height: 22px;
    margin-bottom: 16px;
    -webkit-appearance: none;
    appearance: none;
}

.small-text {
  font-size: 0.85em;
}

.subtle {
  opacity: 0.6;
}

@media (max-width: 1449.98px) {
  .main-container {
    max-width: 850px;
  }
}

@media (max-width: 991.98px) {
  .main-container {
    padding: 0 15px;
  }
}

@media (max-width: 750px) {
  p, li, .sub-text {
    font-size: .9em;
  }
}