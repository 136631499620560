.popup-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    text-align: center;

    background-color: rgba(0, 0, 0, 0.7);

    display: flex;
    justify-content: center;
}

.popup {
    border-radius: 6px;
    border-bottom: 5px solid #5f7e88;
    background-color: white;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);

    margin: 0px 10px;
    max-width: 500px;
    position: fixed;
    top: 50%;
    transform: translateY(-100%);
    padding-bottom: 10px;

    .popup-header {
        .title {
            text-align: center;
            font-weight: bold;
        }
    }

    .popup-content {
        padding: 20px;
        font-size: .9em;
    }

    .popup-buttons {
        text-align: center;
    }
}