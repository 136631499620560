.survey-results {
    padding: 20px 0;
    display: flex;
    justify-content: center;

    sub {
        font-size: .7em;
    }

    .results-container {
        max-width: 64%;
    }
}

.results-header {
    border-radius: 6px;
    border-bottom: 5px solid #5f7e88;
    background-color: white;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);

    padding: 20px;
    margin: 0;

    h3 {
        margin: 0;
    }

    .counter {
        margin-top: 15px;
    }
}

.question-title {
    padding-bottom: 20px;
    margin-bottom: 5px;
    border-bottom: 1px solid rgba(168, 169, 171, 0.5);
}

.question-result-container {
    border-radius: 6px;
    background-color: white;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);

    padding: 20px;
    margin-top: 30px;
}

.average-container {
    display: flex;
    margin: 15px 0;
    align-items: center;

    .bar {
        height: 100%;
        background-color: #5f7e88;
        border-radius: 2px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .value {
        color: rgba(0, 0, 0, 0.6);
        font-weight: bold;
        width: 40px;
        text-align: center;
    }

    .left {
        width: 160px;
        text-align: right;
        margin-right: 15px;
    }

    .right {
        flex-grow: 1;
        height: 50px;
    }
}

@media (max-width: 991.98px) {
    .survey-results {
        .results-container {
            max-width: initial;
            padding: 0 15px;
        }
    }

    .question-result-container {
        font-size: .8em;
    }

    .average-container {
        .left {
            width: 50px;
        }
    }
}

@media (max-width: 1449.98px) {
    .survey-results {
        .results-container {
            max-width: initial;
            padding: 0 15px;
        }
    }

    .question-result-container {
        font-size: .8em;
    }

    .average-container {
        .left {
            width: 50px;
        }
    }
}