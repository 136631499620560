.login {
    display: flex;
    justify-content: center;
}

.login-container {
    min-height: calc(100vh - 71px);
    display: flex;
    flex-direction: column;
    width: 450px;
    margin-top: 50px;

    h1 {
        text-align: center;
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 10px;

        & > * {
            width: 100%;
        }
    }

    .redirect-register {
        text-align: right;

        a {
            padding-left: 5px;
            cursor: pointer;
        }
    }
}