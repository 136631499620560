.dashboard {

    .survey-container {
        .survey-top {
            padding: 30px;
        }

        h4 {
            margin-top: 0;
        }

        max-width: 400px;
        border-radius: 6px;
        border-bottom: 5px solid #5f7e88;
        margin-top: 15px;
        background-color: white;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);

        .survey-description-container {
            display: flex;
            flex-direction: column;
            .description-block {

                &:not(:last-of-type) {
                    margin-bottom: 5px;
                }
            }
        }

        .survey-bottom {
            cursor: pointer;
            transition: .2s;

            &:hover {
                background-color: rgba(68, 130, 150, .1);
            }

            &:active {
                background-color: rgba(42, 84, 99, 0.1);
            }

            text-align: center;
            padding: 10px;
            border-top: 1px solid rgba(68, 130, 150, .1);
            font-weight: bold;
        }
    }

    .empty-survey-filler {
        text-align: center;
        margin-top: 30px;
    }

    .survey-separator {
        display: flex;
        flex-direction: row;
        align-items: center;

        &:not(:first-of-type) {
            margin-top: 60px;
        }

        .line {
            height: 1px;
            background-color: rgba(0, 0, 0, 0.2);

            &.pre {
                width: 30px;
            }
            
            &.post {
                flex: 1;
            }
        }

        label {
            white-space: nowrap;
            padding: 0 15px;
        }
    }
}

@media (max-width: 750px) {
  .dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .survey-separator {
        .line {
            height: 1px;
            background-color: rgba(0, 0, 0, 0.2);
      
            &.pre {
                width: 60px;
            }
            
            &.post {
                width: 60px;
            }
        }
    }
  }
}
